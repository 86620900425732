@import 'Styles/includes';

.ContentModule {
    $root: &;

    margin: 56px 0 40px;

    @include media(M) {
        margin: 80px 0 60px;
    }

    @include media(L) {
        margin: 118px 0 80px;
    }

    &__Wrap {
        @include wrap;
    }

    &__Content {
        max-width: map-get($maxwidths, content);
        margin: 0 auto;
        padding: 24px 32px 32px;
        background: white;

        @include media(M) {
            padding: 40px 48px 56px;
        }

        @include media(L) {
            padding: 44px 65px 76px;
        }
    }

    &__Title {
        @include textstyle(h3);

        width: 100%;
        flex: 1 1 auto;

        @include media(M) {
            width: auto;
        }
    }

    &__Text {
        @include textstyle(body-regular);

        width: 100%;
    }
}
