@import 'Styles/includes';

.CardPerson {
    $root: &;

    flex: 1 0 auto;
    position: relative;
    display: flex;
    padding: 24px 24px 2px;
    margin-bottom: 22px;
    background: white;
    flex-wrap: nowrap;
    flex-direction: row;

    &--AllowPortrait {
        @include media(M) {
            flex-direction: column;
        }

        @include media(L) {
            flex-direction: row;
        }
    }

    &::after {
        content: '';
        width: 100%;
        height: 0;
        position: absolute;
        bottom: -22px;
        left: 0;
        box-sizing: border-box;
        border-top: 22px solid white;
        border-right: 38px solid transparent;
    }

    &__Image {
        position: relative;
        flex: 0 0 auto;
        width: 100px;
        height: 100px;
        min-width: 100px;
        margin-right: 24px;

        @include media(S) {
            width: 140px;
            height: 140px;
            min-width: 140px;
        }

        #{$root}--AllowPortrait & {
            @include media(M) {
                margin-bottom: 24px;
                margin-right: 0;
            }

            @include media(L) {
                margin-bottom: 0;
                margin-right: 24px;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__Content {
        width: calc(100% - 100px - 24px);
        flex: 0 1 auto;
        display: flex;
        flex-flow: column;

        @include media(S) {
            width: calc(100% - 140px - 24px);
        }

        #{$root}--AllowPortrait & {
            @include media(M) {
                width: 100%;
            }

            @include media(L) {
                width: calc(100% - 140px - 24px);
            }
        }
    }

    &__Label {
        @include textstyle(label);

        flex: 0 1 auto;
        margin-bottom: 8px;
    }

    &__Name {
        @include textstyle(h5);

        flex: 0 1 auto;
        word-break: break-word;
    }

    &__CareerTitle {
        @include textstyle(body-small);

        flex: 0 1 auto;
        margin-top: 10px;
        margin-bottom: 2px;
        word-break: break-word;
    }

    &__Link {
        @include textstyle(body-small);

        flex: 0 1 auto;
        color: $purple;
        margin-top: 10px;
        word-break: break-word;

        svg {
            display: inline-block;
            position: relative;
            top: 2px;
            width: 18px;
            height: 13px;
            margin-right: 8px;
            fill: $purple;
            transition: fill $transition;
        }

        &--Phone {
            svg {
                top: 3px;
                height: 16px;
            }
        }

        :global(.Theme--Arkitekturgalan) & {
            color: $arkitekturgalan-link;

            svg {
                fill: $arkitekturgalan-link;
            }
        }

        &:hover {
            color: $grey !important;

            svg {
                fill: $grey !important;
            }
        }
    }

    &__LinkText {
        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 1px;
            background: $purple;
            transition: background $transition;

            #{$root}__Link:hover & {
                background: $grey !important;
            }

            :global(.Theme--Arkitekturgalan) & {
                background: $arkitekturgalan-link;
            }
        }
    }
}
