@import 'Styles/includes';

.Entrances {
    $root: &;

    margin: 40px 0;

    @include media(M) {
        margin: 60px 0;
    }

    @include media(L) {
        margin: 80px 0;
    }

    &__Wrap {
        @include wrap;
    }

    &__List {
        @extend %grid__list;
    }

    &__Item {
        @extend %grid__item;
        @extend %grid__item--half;

        @include media(M) {
            min-height: 320px;
        }
    }
}
