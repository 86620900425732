@import 'Styles/includes';

.GutenbergModule {
    $root: &;

    @include clearfix;

    padding: 16px 0 24px;
    background: white;
}
