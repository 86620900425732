@import 'Styles/includes';

.LinkList {
    $root: &;

    background-color: white;
    width: 100%;
    padding: 24px;

    &__Title {
        @include textstyle(label);

        flex: 0 1 auto;
        margin-bottom: 12px;
    }

    &__List {
    }

    &__Item {
        display: block;
    }

    &__Link {
        @include button(tertiary);

        margin-bottom: -2px;

        @include href-external {
            @include button(tertiary external);
        }

        /* Make sure external icon isn't included */
        @include href-download {
            @include button(tertiary download);

            &::after {
                display: none !important;
            }
        }
    }
}
