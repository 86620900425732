@import 'Styles/includes';

.FAQModule {
    $root: &;

    /* Don't add background white here, it will be added in the modules
        list instead */

    position: relative;
    padding: 32px 0 40px;

    @include media(M) {
        padding: 52px 0 60px;
    }

    @include media(L) {
        padding: 72px 0 80px;
    }

    &__Wrap {
        @include wrap;

        position: relative;
    }

    &__Header {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-bottom: 24px;

        @include media(M) {
            margin-bottom: 32px;
        }
    }

    &__Title {
        @include textstyle(h2);

        width: 100%;
        flex: 1 1 auto;

        @include media(M) {
            width: auto;
        }
    }

    &__Link {
        @include button(tertiary external);

        margin-top: 8px;
        margin-bottom: -8px;
        margin-left: -14px;
        white-space: nowrap;

        @include media(M) {
            margin-top: -7px;
            margin-left: 0;
        }

        &--Question {
            flex: 0 1 auto;
            max-width: 100%;
            margin: 0 0 0 -24px;
            padding: 24px 53px 24px 24px;
            white-space: normal;

            @include media(M) {
                margin: 0;
            }

            &::after {
                width: 13px;
                height: 13px;
                position: relative;
                margin-bottom: -1px;
                background-image: url('#{$assetsPath}img/linkout--purple.svg');
                transition: transform $transition;

                :global(.Theme--Arkitekturgalan) & {
                    width: 13px;
                    height: 13px;
                    margin-bottom: -1px;
                    background-image: url('#{$assetsPath}img/linkout--arkitekturgalan-blue.svg');
                }
            }

            &:hover {
                &::after {
                    transform: translate(2px, -6px);
                }
            }
        }
    }

    &__List {
        @extend %grid__list;

        @include media(M) {
            margin-bottom: -#{$gutter};
            margin-left: -#{$gutter};
        }
    }

    &__Item {
        @extend %grid__item;
        @extend %grid__item--half;

        align-items: flex-start;

        @include media(M) {
            margin-bottom: $gutter;
            margin-left: $gutter;
        }
    }
}
