@import 'Styles/includes';

.InfoCompetition {
    $root: &;

    margin: 24px 0 0 0;
    width: 100%;
    background-color: white;
    position: relative;

    &::before {
        content: '';
        width: 100%;
        height: 0;
        position: absolute;
        top: -24px;
        left: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom: 24px solid white;
        border-right: 48px solid transparent;
    }

    &__Header {
        padding: 0 16px 16px 16px;
        border-bottom: 1px solid $purple-20;

        @include media(M) {
            padding: 0 24px 24px 24px;
        }
    }

    &__Content {
        padding: 16px;

        @include media(M) {
            padding: 24px;
        }
    }

    &__Title {
        @include textstyle(label);

        margin-bottom: 10px;
    }

    &__Link {
        color: black;
    }

    &__SubTitle {
        @include textstyle(h3);

        margin-top: 24px;
        margin-bottom: 16px;
        transition: color $transition;

        #{$root}__Link:hover & {
            color: $primary-color;
        }
    }

    &__Status {
        margin-top: 10px;
        color: $grey-40;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
    }

    &__StatusLabel {
        @include textstyle(h4);
        display: block;
    }

    &__StatusTitle {
        @include textstyle(body-regular);
        display: block;
    }

    &__DownloadButton {
        @include button(primary download full);
    }

    &__Button {
        @include button(primary full);
        margin-top: 16px;

        @include media(ML) {
            margin-top: 32px;
        }
    }

    &__Data {
        margin: 16px 0 24px 0;

        @include media(ML) {
            margin: 22px 0 24px;
        }

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__DataTitle {
        @include textstyle(h4);
        margin-bottom: 8px;
    }

    &__DataText {
        @include textstyle(body-small);

        margin: 8px 0 12px;

        a {
            text-decoration: underline;
            transition: color $transition;

            &:hover {
                color: $purple;
            }
        }
    }
}
