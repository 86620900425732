@import 'Styles/includes';

.CardEntrance {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;

    @include media(M) {
        min-height: 320px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 0 32px 0 rgba(black, .12);
        opacity: 0;
        transition: opacity $transition;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $z-Card;
    }

    &__Content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px;

        @include media(L) {
            padding: 48px;
        }
    }

    &__Title {
        @include textstyle(h3);

        width: 100%;
    }

    &__Text {
        @include textstyle(body-regular);

        width: 100%;
        margin-top: 14px;

        @include media(M) {
            margin-top: 24px;
        }
    }

    &__Footer {
        width: 100%;
        margin-top: auto;
    }

    &__Button {
        @include button(tertiary);

        margin-top: 24px;

        #{$root}:hover & {
            @include button(tertiary hover, true);
        }
    }
}
