@import 'Styles/includes';

.ContentImage {
    $root: &;

    background: white;

    &__Wrap {
        /* Will be full width in mobile */
        @include wrap((d, SL));

        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        min-height: 300px;

        @include media(L) {
            min-height: 414px;
        }
    }

    &__Image {
        flex: 0 0 auto;
        position: relative;
        width: 100%;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 56%;

            @include media(SL) {
                padding-bottom: 42%;
            }

            @include media(M) {
                display: none;
            }
        }

        @include media(M) {
            width: 50%;
            min-height: 300px;
        }

        @include media(L) {
            min-height: 414px;
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
        }
    }

    &__Content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 24px $gutter;
        order: 1;

        @include media(SL) {
            max-width: (map-get($maxwidths, SL));
            margin: 0 auto;
        }

        @include media(M) {
            width: 50%;
            min-height: 300px;
            max-width: 678px;
            margin: 0 auto 0 0;
            padding: 32px 40px;
            order: 0;
        }

        @include media(L) {
            min-height: 414px;
            max-width: 698px;
            padding: 40px 60px;
        }

        @include media(XL) {
            padding: 60px 80px;
        }

        #{$root}--IsLeft & {
            @include media(M) {
                padding-right: 0;
            }

            @include media(L) {
                padding-right: 0;
            }
        }

        #{$root}--IsRight & {
            @include media(M) {
                padding-left: 0;
            }

            @include media(L) {
                padding-left: 0;
            }
        }
    }

    &__Title {
        @include textstyle(h3);
    }

    &__Text {
        @include textstyle(body-regular);

        margin-top: 16px;
    }

    &__Button {
        @include button(primary full);

        margin-top: 24px;
        max-width: 383px;
    }
}
