@use 'sass:math';

@import 'Styles/includes';

.ContactPersons {
    $root: &;

    margin: 32px 0;

    @include media(M) {
        margin: 60px 0;
    }

    @include media(L) {
        margin: 80px 0;
    }

    &__Wrap {
        @include wrap;

        display: flex;
        flex-wrap: wrap;

        #{$root}--One & {
            @include media(M) {
                justify-content: center;
            }

            @include media(L) {
                max-width: 825px;
            }
        }
    }

    &__Heading {
        flex: 0 0 auto;
        width: 100%;
        max-width: 327px;
        margin-bottom: 40px;

        @include media(M) {
            margin-right: auto;
        }

        #{$root}--Two & {
            margin-right: auto;
        }
    }

    &__Label {
        @include textstyle(label);

        margin-bottom: 8px;
        display: block;
    }

    &__Title {
        @include textstyle(h3);

        max-width: 327px;
    }

    &__List {
        @extend %grid__list;

        flex: 0 1 auto;

        #{$root}--One & {
            max-width: calc(404px + 12px);

            @include media(M) {
                width: 57%;
            }
        }

        #{$root}--Two & {
            @include media(L) {
                width: #{percentage(math.div(2, 3))};
            }
        }
    }

    &__Item {
        @extend %grid__item;

        max-width: 404px;

        #{$root}--Two & {
            @extend %grid__item--half;
        }

        #{$root}--Three & {
            @extend %grid__item--third;
        }
    }
}
